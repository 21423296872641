<template>
 <div>
     <s-form @submit="addItem">
        <h2 class="head font-poppins-semibold  mt-3 text-primary">Testing of French Mini Straw</h2>

        <div class="row">
            <div class="col-lg-12">
                <div class="row mt-4">
                    <div class="col-lg-2">
                        <validated-date-picker format="DD-MM-YYYY" label="Date"
                                               class="c-input-datepicker  text-black field-required" v-model="model.test_date" :rules="rules.common"></validated-date-picker>
                    </div><div class="col-lg-2">
                        <validated-select :options="sampleNoOption" label="Sample No"
                                               class="c-input-select text-black field-required" v-model="model.sample_no" :rules="{required:true}"></validated-select>
                    </div><div class="col-lg-2">
                        <validated-input label="Lot No"
                                               class="text-black field-required" v-model="model.lot_no" :rules="rules.positiveOnly" type="number"></validated-input>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-lg-2">
                        <validated-file-input label-text="Upload file" class="w-10r" v-model="model.test_report"></validated-file-input>
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col-lg-2">
                        <validated-input label="Remarks" class="text-black" v-model="model.remarks"></validated-input>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
            </div>
        </div>
        <div class="row mt-lg-5">
            <div class="col-12">
                <div class="fl-x fl-j-e">
                    <div class="btn-group">
                        <btn text="Save"  :loading="loading" loading-text="Saving..."  class="px-4"></btn>
                        <btn text="Cancel"  type="reset" @click="BackToHome" design="basic-b" class="px-4"></btn>
                    </div>
                </div>
            </div>
        </div>
</s-form>
    </div>
</template>

<script>
import urls from '../../data/urls';
import axios from 'secure-axios';

export default {
    name: 'TestingofFrenchMiniStraw',
    data () {
        return {
            sampleNoOption: [
                { label: 'Medium', value: 'Medium' },
                { label: 'Small', value: 'Small' }
            ],
            loading: false,
            URL: urls.french_mini_straw.addEdit,
            model: {
                test_date: '',
                sample_no: '',
                lot_no: '',
                test_report: '',
                remarks: ''
            },
            rules: {
                positiveOnly: {
                    required: true,
                    customValidator: (value) => {
                        return this.avoidNegetive(value);
                    }
                },
                common: {
                    required: true,
                    customValidator: (value) => {
                        return this.validationDate(value);
                    }
                }
            }
        };
    },
    methods: {
        BackToHome () {
            this.$router.push({ path: '/quality-control/' });
        },
        avoidNegetive (no) {
            const num = no;
            if (num < 0) {
                return 'Please enter a valid number';
            }
            return true;
        },
        validationDate () {
            const checkDate = this.model.test_date;

            const checkDateParts = checkDate.split('-');
            if (checkDateParts.length !== 3) {
                return 'Please enter a valid Test Date in the format DD-MM-YYYY.';
            }
            const checkDateDay = parseInt(checkDateParts[0], 10);
            const checkDateMonth = parseInt(checkDateParts[1], 10);
            const checkDateYear = parseInt(checkDateParts[2], 10);

            const checkDateObj = new Date(checkDateYear, checkDateMonth - 1, checkDateDay);

            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            if (checkDateObj > currentDate) {
                return 'Future Date not allowed.';
            }
            return true;
        },
        async addItem () {
            try {
                this.loading = true;
                const response = await axios.form(this.URL, this.model);
                const result = response.data;
                if (result.success) {
                    this.$notify('Added Successfully', 'Success', { type: 'success' });
                    this.loading = false;
                    await this.$router.push({ path: '/quality-control/' });
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            let errorMessage = '';
                            if (key === '__all__') {
                                errorMessage = `${errors[key]}`;
                            } else {
                                errorMessage = `${key}:  ${errors[key]}`;
                            }
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped>

</style>
